/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Images
import check from 'img/check.svg'

const Hero = styled.div`
  @media (min-width: 992px) {
    min-height: 250px;
  }

  @media (max-width: 991px) {
    min-height: 150px;
  }
`

const ImageBackground = styled(Image)``

const Content = styled(ParseContent)`
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size['18']};
  font-weight: ${props => props.theme.font.weight.m};
  /* text-shadow: 0 0 4px rgba(0, 0, 0, .85); */
  line-height: 35px;

  & h1 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size['100']};
    line-height: 80px;
    padding-bottom: 20px;
  }

  & i, em {
    font-weight: ${props => props.theme.font.weight.s};
  }

  & li {
    list-style: none;
    position: relative;
    font-size: ${props => props.theme.font.size.l};
    font-weight: ${props => props.theme.font.weight.s};

    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      left: -30px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${check});
    }
  }
`

const Fade = styled.div`
  background-color: ${props => props.theme.color.face.main};
  z-index: -1;
  max-height: 700px;

  ${ImageBackground} {
    max-height: 700px;
    min-height: 600px;
    height: 100%;
    width: 100%;
    opacity: 0.10;
  }
`

const Background = styled.div`
  height: 700px;
  width: 100%;
  position: absolute;

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const ContentRow = styled.div`
  padding: 50px 0 0 0;
`

const ImageContainer = styled.div`
  padding-top: 100px;
  z-index: -1;
`

const FilterButton = styled.div`
  cursor: pointer;

  ${props => props.selected ? css`
    background-color: ${props.theme.color.face.secondary};
    color: ${props.theme.color.text.light};
  ` : css`
    background-color: transparent;
    color: ${props.theme.color.text.secondary};
  `}

  font-size: ${props => props.theme.font.size.xm};
  text-align: center;
  padding: 10px 0 10px 0;
  width: 185px;
  border-radius: 31px;
  border: 2px solid ${props => props.theme.color.face.secondary};
`

const HeroDefault = ({ business, setBusiness, fields }) => (
  <Hero>
    <Background className="row mx-0">
      <Fade className="col-md-6 col-lg-5 px-0">
        <ImageBackground src={fields.background} aboveFold />
      </Fade>
      <ImageContainer className="d-md-block d-none col-md-6 col-lg-7 px-0">
        <Image src={fields.image} aboveFold />
      </ImageContainer>
    </Background>
    <div className="container py-5">
      <ContentRow className="row">
        <div className="col-md-6 col-lg-4 pt-4 pt-md-5 pb-md-0">
          <Content content={fields.description} />
          <div className="d-flex justify-content-center justify-content-md-start py-4">
            <FilterButton className="mr-4" onClick={() => setBusiness('particulier')} selected={business === 'particulier'}>
              THUIS
            </FilterButton>
            <FilterButton onClick={() => setBusiness('zakelijk')} selected={business === 'zakelijk'}>
              ZAKELIJK
            </FilterButton>
          </div>
        </div>
      </ContentRow>
    </div>
  </Hero>
)

export default HeroDefault