/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroReferences from 'components/elements/references/HeroReferences'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'

// Blog
import BlogikReferenties from 'components/related/Referenties'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  const [business, setBusiness] = useState('particulier')

  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroReferences business={business} setBusiness={setBusiness} fields={acf.banner} />

      <div className="container px-lg-5">
        <div className="px-md-5">
          <Blogik
            settings={{
              limit: 100,
              postType: 'referenties',
              outsideFilter: business
            }}
          >
            <BlogConsumer>
              {({ hasPosts, posts }) => {
                return (
                  <div>
                    {hasPosts && (
                      <>
                        <BlogikReferenties posts={posts} />
                      </>
                    )}

                    {!hasPosts && (
                      <ParseContent content="Geen resultaten" />
                    )}
                  </div>
                )
              }}
            </BlogConsumer>
          </Blogik>
        </div>
      </div>


      <div className="py-5" />
      <div className="py-5" />
      <div className="py-5" />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
